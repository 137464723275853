<template>
  <div class="public-scroll-transparent public-page-style">
    <div class="progress" v-if="progressVis"><el-progress type="circle" :percentage="progress"></el-progress>
      <div style="margin-top :5px;color:#fff;">正在上传中....</div>
    </div>
    <input type="file" id="files" @change="filesWork" style="display:none;">
    <input type="file" id="fileImg" @change="fileVideoImg" style="display:none;">
    <div slot="header" class="clearfix">
      <el-form :inline="true">
        <el-button
          type="primary"
          size="mini"
          class="iot-menu-create"
          @click="create()"
          >新增根目录</el-button
        >
        <el-button
          type="primary"
          plain
          size="mini"
          class="iot-menu-create"
          @click="sortDialong()"
          >根目录排序</el-button
        >
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="workId"
      border
      stripe=""
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      >
      <el-table-column label="菜单名称" prop="name" width="500px">
      </el-table-column>
      <el-table-column label="创建日期" prop="createTime" width="250px">
      </el-table-column>
 
      <!-- <el-table-column label="菜单类型" width="100px">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.workType == 0">菜单</el-tag>
          <el-tag type="success" v-else-if="scope.row.workType == 1"
            >服务</el-tag
          >
          <el-tag v-else-if="scope.row.workType == 2">按钮</el-tag>
        </template>
      </el-table-column> -->

      <el-table-column label="操作" prop="desc">
        <template slot-scope="scope">
          <el-button
          
          plain
          size="mini"
          v-if="scope.row.children.length>0"
          @click="sortDialong(scope.row)"
          >子目录排序</el-button
        >
          <el-button
            type="primary"
            size="mini"
            v-if="scope.row.workType!=2"
            @click.native="create(scope.row)"
            >新增子目录</el-button
          >
            <el-button
            size="mini"
            type="primary"
            plain
            v-if="scope.row.workType != 1&&scope.row.children.length==0" 
            @click.native="file(scope.row)"
            >上传文件</el-button
          >
          <el-button
            size="mini"
            type="primary"
            plain
            v-if="scope.row.workType == 2"
            @click.native="fileImg(scope.row)"
            >上传封面图片</el-button
          >
          <el-button
            size="mini"
            type="warning"
            plain
            @click.native="edit(scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click.native="del(scope.row.workId)"
            >删除</el-button
          >
        
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
     top="15%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="workOrderVisinbile"
      width="30%"
      :before-close="handleClose"
    >
      <el-form  ref="workRef" label-width="100px" :rules="workRules">
        <el-form-item
          label="菜单类型"
        >
        <el-radio-group v-model="workOrderForm.workType" >
      <el-radio
            v-for="item in constant.workTypeBtn"
            :disabled="parentType==1&&item.key!=2||editFlag"
            :label="item.key"
            :key="item.key"
            >{{ item.value }}</el-radio
          >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="菜单名称"
        >
        <el-input v-model="workOrderForm.name" autocomplete="off"></el-input>
        </el-form-item>
      
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" plain size="mini" @click="submitForm"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="workOrderVisinbile = false"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
    <el-dialog
    top="10%"
    title="目录排序"
      :show-close="false"
      :visible.sync="sortVisinbile"
      width="50%"
      :before-close="handleClose"
    >
    <div style="display:flex;justify-content: center;">
      <el-transfer v-model="sortValue" target-order="push" :data="sortData"></el-transfer>
    </div>
     
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" plain size="mini" :disabled="sortData.length!=sortValue.length" @click="submitSort"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="sortVisinbile = false"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { PRIMARY_COLOR } from "../util/theme";
import { workTypeBtn } from "../util/constant";
let that;
export default {
  name: "WorkOrder",
  data() {
    return {
      editFlag:false,
      progressVis:false,
      progress:0,
      sortData:[],
      sortValue:[],
      sortVisinbile:false,
       constant: {
        workTypeBtn,
      },
      theme: { PRIMARY_COLOR },
      formTitle:"",
      workOrderVisinbile:false,
      tableData:[],
      workType:0,
      workOrderForm:{
        name:"",
        parentId:0,
        workType:0,
        workId:"",
        workOrder:"",
      },
      workId:"",
      workRules:{},
      parentType:0,
    };
    
  },
  methods: {
    sortDialong(item=null){
      that.sortData=[];
      that.sortValue=[];
      if(item==null){
        that.tableData.forEach(element => {
        that.sortData.push({
          "key":element.workId,
          "label":element.name,
        });
      });
      }else{
        item.children.forEach(element=>{
          that.sortData.push({
          "key":element.workId,
          "label":element.name,
        });
        });
      }
      that.sortVisinbile=true;
    },
    submitSort(){
      let arr=[];
      that.sortValue.forEach(element => {
        arr.push({"workId":element})
      });
    
       that.$api.workOrder.ediWorkTorderBy(arr).then(res=>{
         that.sortVisinbile=false;
        if (res.code==200) {
          that.$message({
            type: "success",
            message: res.msg,
          })
          that.getALLWorkOrderLists();
          }else{
            that.$message({
            type: "error",
            message: res.msg,
          })
          }
       })
    },
    file(from){
      that.workOrderForm.workId=from.workId;
      let files= document.getElementById("files");
      files.click();
    },
    filesWork(e){
      that.progress=0;
      let form=new FormData();
      let file=e.target.files[0];  
     
     
         if (file.type.split('/')[0]=="video") {
           if (file.size>31457280) {
       this.$message({
           type: "warning",
            message: "视频不能超过30MB！",
       })
       return  
      }
      }else if (file.type.split('/')[1]=="pdf") {
        if (file.size>3145728) {
       this.$message({
           type: "warning",
            message: "PDF文件不能超过3MB！",
       })
       return  
      };
         }else{
        this.$message({
           type: "warning",
            message: "上传文件类型不对！",
       })
       return  
         }
      that.progressVis=true;
      form.append("workId",that.workOrderForm.workId);
      form.append("file",file);
      let onUploadProgress=function(progressEvent){ 
        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) 
       that.progress=complete<99?complete:99;
       };
        that.$api.workOrder.fileWorkOrder(form,onUploadProgress).then(res=>{
        let files= document.getElementById("files");
        files.value=""
        that.progressVis=false;
        if (res.code==200) {
          let workFrom={
            "workId":"",
            "workOrder":"",
          }
          workFrom.workOrder=res.data;
          workFrom.workId=that.workOrderForm.workId;
          that.editWorkOrder(workFrom);
          
        }else{
           that.$message({
           type: "error",
            message: res.msg,
       })
        }
      })
      
      
    },
    fileImg(from){
      that.workOrderForm.workId=from.workId;
      let files= document.getElementById("fileImg");
      files.click();
    },
    fileVideoImg(e){
      that.progress=0;    
      let form=new FormData();
      let file=e.target.files[0];   
        if (file.type.split('/')[0]!="image") {
            this.$message({
           type: "warning",
            message: "请上传图片！",
       })
          return
        }
           if (file.size>1048576) {
       this.$message({
           type: "warning",
            message: "图片不能超过1MB！",
       })
       return  
      }
      that.progressVis=true;
      form.append("workId",that.workOrderForm.workId)
      form.append("file",file);
     let onUploadProgress=function(progressEvent){ 
        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) 
       that.progress=complete<99?complete:99;
       };
        that.$api.workOrder.fileWorkOrder(form,onUploadProgress).then(res=>{
           that.progressVis=false;
           let fileInput= document.getElementById("fileImg");
           fileInput.value="";
        if (res.code==200) {
          let workFrom={
            "workId":"",
            "workImg":"",
          }
          workFrom.workImg=res.data;
          workFrom.workId=that.workOrderForm.workId;
          that.editWorkOrder(workFrom);
        }else{
           that.$message({
           type: "error",
            message: res.msg,
       })
        }

      })
      
      
    },
    getALLWorkOrderLists(){
      that.$api.workOrder.getALLWorkOrderLists().then((res=>{
        if (res.code==200) {
          that.tableData=res.data;
        }
      }))
    },
    create(item) {
      that.editFlag=false
      that.workOrderVisinbile=true;
      that.workOrderForm.name="";
      if (!item) {
      that.workOrderForm.parentId=0;
      that.parentType=0;
      that.workOrderForm.workType=0;
      }else{
      that.workOrderForm.parentId=item.workId?item.workId:0;
      that.parentType=item.workType?item.workType:0;
      if (item.workType==1) {
        that.workOrderForm.workType=2;
      }else{
         that.workOrderForm.workType=0;
      }
      }
      
      
    
      that.workOrderForm.workId="";
      that.workOrderForm.workOrder="";
      
    },
    edit(item) {
      that.editFlag=true;
        if (item.workType==2) {
          that.parentId=1;
        }
         that.$api.workOrder.getWorkOrder(item.workId).then((res) => {
           if (res.code==200) {
             that.workOrderVisinbile=true;
             that.workOrderForm=res.data;
           }
          });
    },
    del(workId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.workOrder.delWorkOrder(workId).then((res) => {
             that.getALLWorkOrderLists();
            that.$message({
              message: "删除成功",
              type: "success",
            });
          });
        });
    },
    submitForm(){
     
      
      if (that.workOrderForm.workId) {
        that.editWorkOrder(that.workOrderForm);
      }else{
      that.$api.workOrder.addWorkOrder(that.workOrderForm).then((res) => {
      that.workOrderVisinbile=false;
      that.workOrderForm.parentId=0;
      that.workOrderForm.name="";
      that.workOrderForm.workType=0;
      that.workOrderForm.workId="";
      that.workOrderForm.workOrder="";
      that.getALLWorkOrderLists();
      });
      }
    
    },
    editWorkOrder(form){
      that.$api.workOrder.editWorkOrder(form).then((res) => {
      that.editFlag=false;
      that.workOrderVisinbile=false;
      that.workOrderForm.parentId=0;
      that.workOrderForm.name="";
      that.workOrderForm.workType=0;
      that.workOrderForm.workId="";
      that.workOrderForm.workOrder="";
      that.getALLWorkOrderLists();
      });
    },
    handleClose(){
         that
        .$confirm("关闭不会保存当前信息，确定要关闭吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
      that.workOrderForm.parentId=0;
      that.workOrderForm.name="";
      that.workOrderForm.workType=0;
      that.workOrderForm.workId="";
      that.workOrderForm.workOrder="";
          that.workOrderVisinbile=false;
        })
    }
 
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getALLWorkOrderLists();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.el-dropdown-link {
  cursor: pointer;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  font-size: 14px;
  margin-bottom: 20px;
}
.iot-menu-create {
  float: right;
  margin: 10px;
}
.iot-table-color {
  background: oldlace;
}
.progress{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 9999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
::v-deep .el-progress__text{
  color: #fff !important;
}
</style>
